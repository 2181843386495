import React from "react";
import shivani from "../utils/images/shivani.jpeg";
import { FaLinkedin } from "react-icons/fa";

export default function Meet() {
  return (
    <div className="container" id="contact">
      <div className="bb" />
      <div className="row mar">
        <div className="col-lg-8 py-5">
          <h1 className="title">Meet Shivani Ranawat</h1>
          <p className="para">
            As a digital expert, Shivani Ranawat is a master of Google AdWords,
            Meta Ads, Amazon Ads, DV360, and more. With a proven track record of
            delivering exceptional results, Shivani crafts marketing campaigns
            that skyrocket sales and revenue. Whether you have a big or small
            budget, Shivani ensures that every dollar spent translates into
            tangible returns, often exceeding expectations.
          </p>
          <a
            href={
              "https://www.linkedin.com/in/shivani-ranawat-a16929122/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="btn btn-primary btn-block mt-4">
              <FaLinkedin size={24} />{" "}
              <span className="mx-2">Connect on LinkedIn</span>
            </button>
          </a>
        </div>
        <div className="col-lg-4">
          <img alt="shivani" src={shivani} />
        </div>
      </div>
    </div>
  );
}
