import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./screens/Home";
import Portfolio from "./screens/Portfolio";
import About from "./screens/About";
import GoogleTagManager from "./components/GoogleTagManager";
import Services from "./screens/Services";
import Contact from "./screens/Contact";
import Page from "./screens/Page";
import ServiceWebDevelopment from "./screens/ServiceWebDevelopment";
import ServiceProgrammaticAdvertisement from "./screens/ServiceProgrammaticAdvertisement";
import ServicePerformanceMarketing from "./screens/ServicePerformanceMarketing";
import ServiceMediaMath from "./screens/ServiceMediaMath";

export default function App() {
  return (
    <Router>
      <div>
        <GoogleTagManager />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/services" element={<Services />} />
          <Route
            path="/services/web-development"
            element={<ServiceWebDevelopment />}
          />
          <Route
            path="/services/programmatic-advertisement"
            element={<ServiceProgrammaticAdvertisement />}
          />
          <Route
            path="/services/performance-marketing-services"
            element={<ServicePerformanceMarketing />}
          />
          <Route
            path="/services/mediamath-dsp-solutions"
            element={<ServiceMediaMath />}
          />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Page />} />
        </Routes>
      </div>
    </Router>
  );
}
