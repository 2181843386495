import React, { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";

export default function Stats() {
  const targetRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    });

    observer.observe(targetRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <div className="container" ref={targetRef}>
      <h1 className="title text-center mt-4">Our Statistics</h1>
      <div className="row ht d-flex justify-content-center align-items-center mx-auto">
        <div className="col-md-4 my-5 text-center">
          <div class="counter" data-count="31"></div>
          {isVisible && (
            <CountUp className="fw-bold display-3" end={31} duration={4} />
          )}
          <span className="fw-bold display-3">+</span>
          <p>Websites Developed</p>
        </div>
        <div className="col-md-4 my-5 text-center">
          {isVisible && (
            <CountUp className="fw-bold display-3" end={89} duration={4} />
          )}
          <span className="fw-bold display-3">+</span>
          <p>Apps Marketed</p>
        </div>
        <div className="col-md-4 my-5 text-center">
          {isVisible && (
            <CountUp className="fw-bold display-3" end={21} duration={4} />
          )}
          <span className="fw-bold display-3">+</span>
          <p>Digital Marketing Project</p>
        </div>
      </div>
    </div>
  );
}
