import React from "react";

import divine from "../utils/images/divine.webp";
import nutra from "../utils/images/nutra.webp";
import swas from "../utils/images/swas.avif";
import tirth from "../utils/images/tirth.png";
import within from "../utils/images/within.png";

const logos = [divine, nutra, swas, tirth, within];

export default function LogoListing() {
  return (
    <div className="mx-auto">
      <div className="logo-marquee">
        <div className="logo-marquee-inner">
          {logos.map((logo, index) => (
            <div key={index} className="logo-container">
              <img
                src={logo}
                alt={`Company Logo ${index + 1}`}
                className="img-fluid company-logo"
              />
            </div>
          ))}
          {logos.map((logo, index) => (
            <div key={index + logos.length} className="logo-container">
              <img
                src={logo}
                alt={`Company Logo ${index + logos.length + 1}`}
                className="img-fluid company-logo"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
