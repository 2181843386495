import React from "react";
import { FiStar } from "react-icons/fi";

const testimonials = [
  {
    text: "AIADspace transformed our online presence. Our traffic and sales have never been better!",
    author: "Gautam",
    position: "Founder, Ugokasfoundation",
    rating: 5,
  },
  {
    text: "Their creative designs and marketing strategies are top-notch. Highly recommend!",
    author: "Rinam Sahoo",
    position: "Founder, Divinehandicrafts",
    rating: 4,
  },
  {
    text: "AIADspace's digital marketing strategy has been a game-changer for us. Our online presence has significantly improved!",
    author: "Sarah Lee",
    position: "Marketing Director",
    rating: 5,
  },
  {
    text: "The website development team at AIADspace delivered a stunning, functional site that exceeded our expectations.",
    author: "Michael Brown",
    position: "CEO",
    rating: 5,
  },
  {
    text: "From branding to digital campaigns, AIADspace has been instrumental in our growth.",
    author: "Lisa White",
    position: "COO",
    rating: 4,
  },
];

function Testimonial() {
  return (
    <div
      className="container my-5 mx-auto"
      id="testimonials"
      data-aos="fade-down"
    >
      <h3 className="title text-center pb-5">What our client's say</h3>
      <div className="d-flex flex-wrap justify-content-center align-items-center">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-container box p-4  m-1">
            <p>{testimonial.text}</p>
            <div>
              {Array.from({ length: testimonial.rating }, (_, i) => (
                <FiStar key={i} color="orange" />
              ))}
            </div>
            <div className="bbx"></div>
            <p className="fw-bold mt-1 m-0">{testimonial.author}</p>
            <p>{testimonial.position}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Testimonial;
