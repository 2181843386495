import React from "react";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import pag from "../utils/images/pag.png";

export default function Page() {
  return (
    <div>
      <Banner
        title={"Error: 404, Page Not Found."}
        subtitle={
          "You are not where you wanted to be. Please click on the homepage link to go back."
        }
      />
      <div className="container vh-50 pad d-flex flex-column align-items-center justify-content-center flex-wrap">
        <img
          src={pag}
          style={{ width: "300px", height: "300px", objectFit: "contain" }}
          alt="pag"
        />
        <p className="para">
          Go Back to{" "}
          <a href="/" className="text-primary">
            HomePage
          </a>
        </p>
      </div>
      <Footer />
    </div>
  );
}
