import React from "react";
import Banner from "../components/Banner";
import Footer from "../components/Footer";

export default function ServiceWebDevelopment() {
  return (
    <div>
      <Banner
        title={"Web Development"}
        subtitle={
          "Our mission is to create stunning, user-friendly websites that not only reflect your brand's unique identity but also drive measurable results."
        }
      />
      <main className="container pad">
        <section>
          <h2>Welcome to Aiadspace's Website Development Agency</h2>
          <p>
            At Aiadspace, we understand that your website is the digital
            storefront of your business. Our mission is to create stunning,
            user-friendly websites that not only reflect your brand's unique
            identity but also drive measurable results. Whether you’re launching
            a new product, rebranding, or simply looking to improve your online
            presence, our team of experts is here to help you every step of the
            way.
          </p>
        </section>

        <section>
          <h2>Our Website Development Services</h2>
          <ul>
            <li>
              <strong>Custom Website Design:</strong> We specialize in creating
              bespoke website designs tailored to your specific needs and goals.
              Our process begins with understanding your brand, audience, and
              objectives to ensure your website is visually appealing and
              functionally robust.
            </li>
            <li>
              <strong>Responsive Web Design:</strong> In today’s mobile-first
              world, having a responsive website is crucial. Our designs are
              mobile-friendly, ensuring that your site looks and performs well
              on all devices, from desktops to smartphones.
            </li>
            <li>
              <strong>eCommerce Solutions:</strong> We provide comprehensive
              eCommerce solutions that make online selling seamless and
              efficient. From simple online stores to complex custom solutions,
              we integrate the latest technologies to provide a secure and
              user-friendly shopping experience.
            </li>
            <li>
              <strong>SEO-Friendly Development:</strong> A beautiful website is
              only effective if it can be found by your target audience. Our
              development process incorporates SEO best practices to ensure your
              site ranks well in search engine results.
            </li>
            <li>
              <strong>Content Management Systems (CMS):</strong> We build
              websites on robust CMS platforms like WordPress, Joomla, and
              Drupal, giving you the flexibility to manage your content easily.
            </li>
            <li>
              <strong>UX/UI Design:</strong> User experience (UX) and user
              interface (UI) design are at the heart of our web development
              process. We create intuitive, easy-to-navigate websites that keep
              users engaged and encourage them to take action.
            </li>
            <li>
              <strong>Website Maintenance and Support:</strong> Our relationship
              with you doesn’t end once your site goes live. We offer ongoing
              maintenance and support services to ensure your website remains
              up-to-date, secure, and performing optimally.
            </li>
          </ul>
        </section>

        <section>
          <h2>Our Process</h2>
          <ol>
            <li>
              <strong>Discovery and Planning:</strong> We begin with a detailed
              consultation to understand your vision, goals, and requirements.
              This phase involves competitor analysis, market research, and
              defining the project scope.
            </li>
            <li>
              <strong>Design and Prototyping:</strong> Our creative team designs
              wireframes and prototypes to give you a visual representation of
              your website. You’ll have the opportunity to provide feedback and
              make revisions before we move to the development stage.
            </li>
            <li>
              <strong>Development:</strong> Our developers bring the design to
              life using the latest technologies and coding practices. We ensure
              your website is fast, secure, and scalable.
            </li>
            <li>
              <strong>Testing and Quality Assurance:</strong> Before launching,
              we conduct thorough testing across various devices and browsers to
              ensure your website performs flawlessly. We also perform usability
              testing to ensure a great user experience.
            </li>
            <li>
              <strong>Launch and Optimization:</strong> We launch your website
              at an optimal time and ensure it is indexed by search engines.
              Post-launch, we provide training on managing your site and offer
              continuous optimization services to improve performance.
            </li>
            <li>
              <strong>Ongoing Support:</strong> Our team remains available for
              any support or maintenance needs, ensuring your website remains in
              top condition.
            </li>
          </ol>
        </section>

        <section>
          <h2>Contact Us</h2>
          <p>
            At Aiadspace, we are committed to delivering exceptional website
            development services that help you achieve your business goals.
            Contact us today to learn more about how we can transform your
            online presence.
          </p>
        </section>
      </main>

      <Footer />
    </div>
  );
}
