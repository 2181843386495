import React from "react";

export default function SectionContent({ title, btnTitle, link }) {
  return (
    <div className="pad text-center bg-primary">
      <h1 className="pad">{title}</h1>
      <a href={link}>
        <button className="custom-btn btn btn-primary">{btnTitle}</button>
      </a>
    </div>
  );
}
