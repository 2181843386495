import React from "react";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import contact from "../utils/images/contact.jpeg";
import { FiMail, FiPhoneCall } from "react-icons/fi";

export default function Contact() {
  return (
    <div>
      <Banner
        title={"Contact Us"}
        subtitle={
          "Let's Connect, Feel free to reach out using the contact form below, or connect with us through our social media channels. We're eager to connect with you and explore how we can work together to achieve your goals."
        }
      />

      <div className="mx-auto container ht d-flex justify-content-center align-items-center flex-wrap">
        <div className="col-md-6 v">
          <img src={contact} alt="contact" />
          <div className="d-flex container p-4 justify-content-around align-items-center flex-wrap">
            <p>
              <FiPhoneCall className="mx-4" size={20} color={"orange"} />
              <a href="tel:+917042136564">7042136564</a>
            </p>
            <p>
              <FiMail className="mx-4" size={20} color={"orange"} />
              <a href="mailto:contact@aiadspace.com">contact@aiadspace.com</a>
            </p>
          </div>
        </div>
        <div className="col-md-6 p-4 pl">
          <iframe
            title="Tally Survey"
            src="https://tally.so/r/3lAWqV"
            width="500px"
            height="500px"
            scrolling="no"
            frameBorder="0"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
