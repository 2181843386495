import React from "react";
import a from "../utils/images/a.png";
import b from "../utils/images/b.png";
import { Button } from "react-bootstrap";

export default function Content() {
  return (
    <div className="container">
      <div className="row my-4">
        <div className="col-12">
          <p className="p-2 subtitle">
            Welcome to AIADspace, where innovation meets excellence in digital
            marketing. With a proven track record of transforming brands, we
            specialize in crafting bespoke strategies that drive tangible
            results. Backed by a portfolio of over 50 international success
            stories, we pride ourselves on delivering measurable growth, with
            some brands experiencing up to 200% increase in their market reach.
            Trust AIADspace to be your strategic partner in navigating the
            digital landscape and unlocking the full potential of your brand.
          </p>{" "}
          <a href={"/contact"}>
            <Button className="custom-btn">Get My Free Proposal</Button>
          </a>
        </div>
      </div>
      <div className="bb" />
      <div
        className="row p-5 justify-content-center align-items-center"
        data-aos="fade-right"
      >
        <div className="col-lg-4">
          <img src={a} alt="img" className="img-fluid" />
        </div>
        <div className="col-lg-8 p-4">
          <h2 className="title">
            Generate High-Quality Leads & Skyrocket Growth with Proven
            Strategies
          </h2>
          <p className="para">
            Unique Digital Marketing Strategies for High-Quality Leads and 5x
            Sales From Sleepless Nights to Viral Success: Turned Millions of
            Businesses into Phenomenal Brands. Our comprehensive approach to
            digital marketing ensures your business stands out in today's
            competitive landscape. Let us transform your online presence and
            unlock new opportunities for success.
          </p>
          <a href="/contact">
            <button className="btn btn-primary">Connect Now</button>
          </a>
        </div>
      </div>
      <div
        className="row p-5 justify-content-center align-items-center my-4"
        data-aos="fade-left"
      >
        <div className="col-lg-8 p-4 v">
          <h2 className="title">What Others Are Saying</h2>
          <p className="para">
            Discover the life-changing stories from entrepreneurs just like you
            who have experienced the transformative power of our strategies.
            With a 4.8 out of 5 rating, our satisfied clients attest to the
            effectiveness of our methods in driving real results.
          </p>
          <a href="#testimonials">
            <button className="btn btn-primary">View Testimonials</button>
          </a>
        </div>
        <div className="col-lg-4">
          <img src={b} alt="img" className="img-fluid" />
        </div>
      </div>
    </div>
  );
}
