import React from "react";
import Banner from "../components/Banner";
import Footer from "../components/Footer";

export default function ServiceProgrammaticAdvertisement() {
  return (
    <div>
      <Banner
        title={"Programmatic Advertisement"}
        subtitle={
          "Transform your digital marketing with Aiadspace. Harness the power of programmatic advertising to reach your audience with precision, efficiency, and impact."
        }
      />
      <main className="container pad">
        <section>
          <h2>Welcome to Aiadspace's Programmatic Advertisement Services</h2>
          <p>
            In today's fast-paced digital world, reaching the right audience at
            the right time is crucial. Programmatic advertising offers the
            precision, efficiency, and scalability you need to stay ahead.
          </p>
          <h3>What is Programmatic Advertising?</h3>
          <p>
            Programmatic advertising automates the buying and selling of online
            ad space, using sophisticated algorithms and real-time bidding (RTB)
            to deliver targeted ads to specific audiences.
          </p>
        </section>

        <section>
          <h2>Benefits of Programmatic Advertising with Aiadspace</h2>
          <ol>
            <li>
              <strong>Precision Targeting:</strong> We use advanced data
              analytics to target your ads with pinpoint accuracy, ensuring
              maximum relevance and engagement.
            </li>
            <li>
              <strong>Real-Time Bidding (RTB):</strong> Our RTB platform
              optimizes your ad spend by bidding on ad impressions in real-time,
              ensuring your ads are placed effectively.
            </li>
            <li>
              <strong>Enhanced Reach and Scalability:</strong> Reach a broader
              audience across devices and platforms with seamless ad delivery.
            </li>
            <li>
              <strong>Efficiency and Cost-Effectiveness:</strong> Automating the
              ad buying process reduces costs and improves marketing ROI.
            </li>
            <li>
              <strong>Transparency and Control:</strong> Gain insights with
              detailed reporting and analytics, optimizing your campaigns for
              better results.
            </li>
          </ol>
        </section>

        <section>
          <h2>Our Programmatic Advertising Services</h2>
          <ul>
            <li>
              <strong>Display Advertising:</strong> Visually engaging ads on
              websites, mobile apps, and social media platforms.
            </li>
            <li>
              <strong>Video Advertising:</strong> Compelling video ads across
              multiple platforms for high engagement.
            </li>
            <li>
              <strong>Native Advertising:</strong> Seamless integration into
              user experiences for effective engagement.
            </li>
            <li>
              <strong>Social Media Advertising:</strong> Targeted ads on popular
              social media platforms to drive engagement.
            </li>
            <li>
              <strong>Retargeting:</strong> Bringing back potential customers
              with personalized ads.
            </li>
          </ul>
        </section>

        <section>
          <h2>Why Choose Aiadspace?</h2>
          <p>
            At Aiadspace, we combine industry knowledge with innovative
            technology to deliver exceptional programmatic advertising
            solutions. Our customized strategies and dedicated support ensure
            your campaigns achieve measurable outcomes.
          </p>
        </section>

        <section>
          <h2>Contact Us</h2>
          <p>
            Ready to revolutionize your advertising strategy? Contact Aiadspace
            today to learn more about our programmatic advertising services and
            how we can help you achieve your marketing objectives.
          </p>
        </section>
      </main>

      <Footer />
    </div>
  );
}
