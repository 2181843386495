import React from "react";
import seo from "../utils/images/seo.png";
import ppc from "../utils/images/ppc.png";
import social from "../utils/images/social.png";
import web from "../utils/images/web.png";
import des from "../utils/images/des.png";
import per from "../utils/images/metrics.png";

const services = [
  {
    img: seo,
    title: "SEO Optimization",
    description:
      "Improve your search engine rankings and drive organic traffic",
    cta: "Get a Quote",
  },
  {
    img: ppc,
    title: "PPC Advertising",
    description: "Targeted ad campaigns to maximize your ROI.",
    cta: "Get a Quote",
  },
  {
    img: social,
    title: "Social Media Marketing",
    description: "Engage and grow your audience on popular social platforms",
    cta: "Get a Quote",
  },
  {
    img: web,
    title: "Website Development",
    description:
      "Utilize Google Ads to maximize your reach and engagement. We design campaigns that drive traffic and conversions.",
    cta: "Get a Quote",
  },
  {
    img: des,
    title: "Creative Design",
    description:
      "Craft unique, eye-catching designs that make your brand stand out.",
    cta: "Get a Quote",
  },
  {
    img: per,
    title: "Performance Marketing",
    description:
      "Leverage data-driven strategies to achieve measurable results and drive growth.",
    cta: "Get a Quote",
  },
];

export default function ServicesGrid() {
  return (
    <div className="container my-5" data-aos="fade-down">
      <div className="bb ms-auto" />
      <h2 className="text-center title mb-4">Our Services</h2>
      <div className="row">
        {services.map((service, index) => (
          <div key={index} className="col-md-4 mb-4">
            <div className="box h-100">
              <img
                src={service.img}
                className="sm"
                alt={`Service ${index + 1}`}
              />
              <div className="mt-4">
                <h5>{service.title}</h5>
                <p>{service.description}</p>
                <a
                  href={"/contact"}
                  className="btn btn-primary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {service.cta}
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
