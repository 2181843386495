import React, { useState } from "react";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import SectionContent from "../components/SectionContent";

import tirth from "../utils/images/tirth.jpeg";
import divine from "../utils/images/divi.avif";
import found from "../utils/images/found.jpeg";
import map from "../utils/images/map.jpeg";
import nutralytes from "../utils/images/nutralytes.png";
import { FiArrowUpRight } from "react-icons/fi";

const projects = {
  "Website Development": [
    {
      name: "My Tirth India",
      description:
        "My Tirth India has put their foot forward to strengthen the tourism and hospitality industry, the company has partnered with over 800 hotels, home stays and transporters in the to provide all logistics and hospitality support to the pilgrims.",
      image: tirth,
      link: "https://www.mytirthindia.com/",
    },
    {
      name: "Divine Handicrafts",
      description:
        "At DivineHandicraft, the focus is on empowering the artisans, preserving cultural heritage, and providing customers with high-quality, exotic handicrafts. The company ensures fair trade practices and pays the artisans a fair price for their work, thus supporting their families and creating value in society.",
      image: divine,
      link: "https://divinehandicrafts.co.uk/",
    },
    {
      name: "Ugokas Foundation",
      description:
        "Ugokas Foundation dedicates itself to creating a brighter future through various impactful initiatives.  There work spans across education, healthcare, environmental conservation, empowerment programs, and more.",
      image: found,
      link: "https://ugokasfoundation.org/",
    },
  ],
  "Digital Marketing": [
    {
      name: "Nutralytes",
      description:
        "Nutralytes is an emerging Pure and Natural brand to provide genuine health products. We intend to provide ultimate health solutions to our customers with an innovative approach to uplift their wellbeing. All our products are examples of premium quality testing that provides you effective results in weight loss, general wellness, hair fall, and scalp issues. Nutralytes is one of the most trusted service providers in the health and wellness that ensures to live up to your overall health needs.Good Health for all is the ultimate mission of the creators of Nutralytes products.",
      image: nutralytes,
      link: "https://www.nutralytes.in/",
    },
    {
      name: "Within 5",
      description:
        "The website provides on-demand services based on the user's location in real time. Users can search for any service within a 5 km radius or for any selected radius.. They can also manually search for services by entering a place name.",
      image: map,
      link: "https://within5.com/",
    },
  ],
};

export default function Portfolio() {
  const [activeTab, setActiveTab] = useState("Website Development");

  return (
    <div>
      <Banner
        title={"Our Portfolio: Showcasing Success Stories"}
        subtitle={
          "Discover the diverse range of projects we've worked on and see how we've helped businesses"
        }
      />
      <Container className="pad">
        <Tabs
          id="portfolio-tabs"
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(key)}
          className="mb-3"
        >
          {Object.keys(projects).map((category) => (
            <Tab key={category} eventKey={category} title={category}>
              <Row>
                {projects[category].map((project, idx) => (
                  <Col md={12} key={idx} className="mb-4" data-aos="fade-down">
                    <Row className="my-4">
                      <Col md={4}>
                        <img
                          src={project.image}
                          className="card-img-top"
                          alt={project.name}
                        />
                      </Col>
                      <Col md={8}>
                        <div className="card-body">
                          <h5 className="heading">{project.name}</h5>
                          <p className="para">{project.description}</p>
                          <a
                            href={project.link}
                            className="btn btn-primary"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Visit the Website <FiArrowUpRight size={20} />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Tab>
          ))}
        </Tabs>
      </Container>
      <SectionContent
        title="Inspired by our work? Let's create something amazing together!"
        btnTitle="Start Your Project"
        link="/contact"
      />
      <Footer />
    </div>
  );
}
