import React, { useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import logo from "../utils/images/logo.png";

export default function Banner({ title, subtitle, h }) {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();

  const handleNavItemClick = () => {
    setExpanded(false); // Close the Navbar when a Nav.Link or NavDropdown.Item is clicked
  };

  return (
    <div className="bg">
      <Navbar
        expand="lg"
        expanded={expanded}
        className="justify-content-between"
      >
        <Navbar.Brand as={Link} to="/">
          <img src={logo} alt="logo" className="img-fluid logo" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link
              as={Link}
              to="/"
              onClick={handleNavItemClick}
              className={location.pathname === "/" ? "active" : ""}
            >
              Home
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/about"
              onClick={handleNavItemClick}
              className={location.pathname === "/about" ? "active" : ""}
            >
              About
            </Nav.Link>
            <NavDropdown title="Services" id="basic-nav-dropdown">
              <NavDropdown.Item
                as={Link}
                to="/services"
                onClick={handleNavItemClick}
                className={
                  location.pathname.startsWith("/services") ? "active" : ""
                }
              >
                All Services
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to="/services/web-development"
                onClick={handleNavItemClick}
                className={
                  location.pathname === "/services/web-development"
                    ? "active"
                    : ""
                }
              >
                Web Development
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/services/programmatic-advertisement"
                onClick={handleNavItemClick}
                className={
                  location.pathname === "/services/programmatic-advertisement"
                    ? "active"
                    : ""
                }
              >
                Programmatic Advertisement
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/services/performance-marketing-services"
                onClick={handleNavItemClick}
                className={
                  location.pathname ===
                  "/services/performance-marketing-services"
                    ? "active"
                    : ""
                }
              >
                Performance Marketing Services
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/services/mediamath-dsp-solutions"
                onClick={handleNavItemClick}
                className={
                  location.pathname === "/services/mediamath-dsp-solutions"
                    ? "active"
                    : ""
                }
              >
                MediaMath DSP Solutions
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              as={Link}
              to="/portfolio"
              onClick={handleNavItemClick}
              className={location.pathname === "/portfolio" ? "active" : ""}
            >
              Portfolio
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/contact"
              onClick={handleNavItemClick}
              className={location.pathname === "/contact" ? "active" : ""}
            >
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="container mx-auto pad">
        <h1 className="title" style={{ fontSize: h }}>
          {title}
        </h1>
        <p className="subtitle pb-5">{subtitle}</p>
      </div>
    </div>
  );
}
