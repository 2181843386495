import React from "react";
import Banner from "../components/Banner";
import Content from "../components/Content";
import Meet from "../components/Meet";
import Footer from "../components/Footer";
import LogoListing from "../components/LogoListing";
import ServicesGrid from "../components/ServicesGrid";
import Stats from "../components/Stats";
import StartProject from "../components/StartProject";
import Testimonial from "../components/Testimonial";
import SectionContent from "../components/SectionContent";

export default function Home() {
  return (
    <div>
      <Banner
        h={"6vw"}
        title={"Transform Your Digital Presence with AIADspace"}
        subtitle={
          "Empowering Businesses with Strategic Branding, Creative Designs, and Digital Marketing"
        }
      />
      <LogoListing />
      <Content />
      <ServicesGrid />
      <SectionContent
        title="Explore our latest projects and see how we've helped businesses like yours succeed"
        link="/portfolio"
        btnTitle="View our Portfolio"
      />
      <Testimonial />
      <Stats />
      <SectionContent
        title="Ready to take your business to the next level? Let's get started today!"
        link="/contact"
        btnTitle="Contact Us Now"
      />
      <Meet />
      <StartProject />
      <Footer />
    </div>
  );
}
