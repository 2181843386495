import React, { useState } from "react";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import SectionContent from "../components/SectionContent";

import {
  FaSearch,
  FaBullhorn,
  FaThumbsUp,
  FaEnvelope,
  FaDesktop,
  FaShoppingCart,
  FaMobileAlt,
  FaTools,
  FaPalette,
  FaPenNib,
  FaDraftingCompass,
  FaPrint,
  FaPlayCircle,
  FaBoxOpen,
} from "react-icons/fa";

const servicesData = {
  services: [
    {
      category: "Digital Marketing Services",
      items: [
        {
          title: "SEO Optimization",
          description:
            "Improve your search engine rankings and drive organic traffic. Our SEO strategies include keyword research, on-page and off-page optimization, and continuous performance tracking to ensure your website ranks higher in search engine results.",
          icon: <FaSearch />,
        },
        {
          title: "PPC Advertising",
          description:
            "Targeted ad campaigns to maximize your ROI. We design and manage pay-per-click campaigns across platforms like Google Ads and Bing Ads to drive traffic and increase conversions.",
          icon: <FaBullhorn />,
        },
        {
          title: "Social Media Marketing",
          description:
            "Engage and grow your audience on popular social platforms. We create and execute social media strategies that boost your brand's presence on Facebook, Instagram, Twitter, LinkedIn, and more.",
          icon: <FaThumbsUp />,
        },
        {
          title: "Email Marketing",
          description:
            "Craft compelling email campaigns that convert. Our email marketing services include designing personalized email templates, segmenting your audience, and analyzing campaign performance.",
          icon: <FaEnvelope />,
        },
        {
          title: "Content Marketing",
          description:
            "Create valuable content to attract and engage your target audience. Our content marketing services encompass blog writing, video production, infographics, and more.",
          icon: <FaPenNib />,
        },
        {
          title: "Influencer Marketing",
          description:
            "Partner with influencers to expand your brand reach. We connect you with influencers who align with your brand values and can help you reach a larger, more engaged audience.",
          icon: <FaBullhorn />,
        },
      ],
    },
    {
      category: "Website Development Services",
      items: [
        {
          title: "Custom Web Design",
          description:
            "Unique, user-friendly designs for your brand. Our web design team creates custom websites that reflect your brand identity and provide a seamless user experience.",
          icon: <FaDesktop />,
        },
        {
          title: "E-commerce Solutions",
          description:
            "Build robust online stores with seamless shopping experiences. We develop e-commerce platforms that are secure, scalable, and optimized for conversions.",
          icon: <FaShoppingCart />,
        },
        {
          title: "Responsive Design",
          description:
            "Ensure your website looks great on all devices. Our responsive design approach guarantees your site performs well on desktops, tablets, and smartphones.",
          icon: <FaMobileAlt />,
        },
        {
          title: "Web Maintenance",
          description:
            "Ongoing updates and support to keep your site running smoothly. We offer maintenance services to ensure your website remains up-to-date and free from issues.",
          icon: <FaTools />,
        },
        {
          title: "CMS Development",
          description:
            "Create a powerful content management system tailored to your needs. We specialize in developing custom CMS solutions that make content management easy and efficient.",
          icon: <FaDesktop />,
        },
        {
          title: "Web Hosting",
          description:
            "Reliable and secure web hosting services. Our hosting solutions provide fast, secure, and scalable options to ensure your website is always accessible.",
          icon: <FaTools />,
        },
      ],
    },
    {
      category: "Creative Design Services",
      items: [
        {
          title: "Brand Identity",
          description:
            "Create a cohesive and impactful brand identity. We design logos, business cards, and other branding materials that represent your business and resonate with your audience.",
          icon: <FaPalette />,
        },
        {
          title: "Graphic Design",
          description:
            "Eye-catching graphics for all your marketing needs. Our graphic design services include creating visuals for social media, advertising, print materials, and more.",
          icon: <FaPenNib />,
        },
        {
          title: "UI/UX Design",
          description:
            "Intuitive and attractive user interfaces for a better user experience. We focus on creating designs that are both visually appealing and easy to navigate.",
          icon: <FaDraftingCompass />,
        },
        {
          title: "Print Design",
          description:
            "Design stunning print materials that make an impact. Our print design services cover brochures, flyers, posters, and other promotional materials.",
          icon: <FaPrint />,
        },
        {
          title: "Motion Graphics",
          description:
            "Engage your audience with dynamic motion graphics. We create animations and video graphics that enhance your digital content.",
          icon: <FaPlayCircle />,
        },
        {
          title: "Packaging Design",
          description:
            "Design innovative packaging that stands out on the shelf. Our packaging design services ensure your products attract attention and communicate your brand message effectively.",
          icon: <FaBoxOpen />,
        },
      ],
    },
  ],
};

export default function Services() {
  const [key, setKey] = useState(servicesData.services[0].category);
  return (
    <div>
      <Banner
        title={"Services"}
        subtitle={
          "Welcome to AIADspace, where we empower businesses through strategic branding, creative designs, and digital marketing excellence. Our mission is to help you achieve unparalleled growth and success in the digital landscape."
        }
      />
      <Container className="my-5" id="services">
        <Tabs
          id="services-tabs"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          {servicesData.services.map((serviceCategory, index) => (
            <Tab
              eventKey={serviceCategory.category}
              title={serviceCategory.category}
              key={index}
            >
              <Row>
                {serviceCategory.items.map((item, idx) => (
                  <Col md={4} key={idx} className="mb-4" data-aos="fade-down">
                    <div className="pad h-100 border rounded box">
                      <h2 className="text-warning mb-5">{item.icon}</h2>
                      <h2 className="heading">{item.title}</h2>
                      <p className="para">{item.description}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </Tab>
          ))}
        </Tabs>
      </Container>
      <SectionContent
        title="Ready to take your business to the next level? Let's get started today!"
        link="/contact"
        btnTitle="Contact Us Now"
      />
      <Footer />
    </div>
  );
}
