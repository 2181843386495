import React from "react";
import { FaArrowCircleRight } from "react-icons/fa";

export default function StartProject() {
  return (
    <div className="bg d-flex justify-content-center align-items-center">
      <div className="container">
        <div className="row">
          <div className="col-md-4 my-5">
            <h2 className="fw-bold display-5 mb-5">
              Start your project with Digital Shift in 4-Simple Steps{" "}
            </h2>
            <FaArrowCircleRight size={50} />
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-6 my-5">
            <div>
              <p className="fw-bold para">
                Step-1: Initial Consultation:
                <ul>
                  <li className="para">
                    Schedule a free consultation to discuss your needs and
                    goals.
                  </li>
                  <a href={"/contact"}>
                    <button className="btn btn-primary">
                      Book a Free Consultation.
                    </button>
                  </a>
                </ul>
              </p>
            </div>
            <div>
              <p className="fw-bold para">
                Step-2 Strategy Development:
                <ul>
                  <li className="para">
                    We analyse your market and create a strategy for your
                    business.
                  </li>
                </ul>
              </p>
            </div>
            <div>
              <p className="fw-bold para">
                Step-3 Implementation:
                <ul>
                  <li className="para">
                    Our team of experts gets to work, bringing your vision to
                    life with precision and creativity.
                  </li>
                </ul>
              </p>
            </div>
            <div>
              <p className="fw-bold para">
                Step-4 Ongoing Support:
                <ul>
                  <li className="para">
                    We provide continuous support and optimization to ensure
                    sustained growth.
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
