import React from "react";
import { Nav } from "react-bootstrap";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { FiHeart, FiMail } from "react-icons/fi";

export default function Footer() {
  return (
    <footer className="footer text-white dark p-3">
      <div className="container-fluid p-1">
        <div className="row mb-4">
          <div className="col-md-6">
            <h5 className="title">AIAdspace</h5>
            <p className="para">
              At AIADspace, we empower businesses with strategic branding,
              creative designs, and digital marketing. With over 50
              international brands achieving 200% growth, we make solutions to
              your unique needs. Our team blends creativity and data-driven
              strategies to help your brand thrive. Let's achieve remarkable
              growth together.
            </p>
          </div>
          <div className="col-md-6 text-end">
            <p>Address</p>
            <p>DLF Phase 2, Sector 25, Gurugram, Haryana 122002</p>
            <p>
              <a className="text-white" href="tel:+917042136564">
                Phone: 7042136564
              </a>
            </p>
            <p>
              <a className="text-white" href="mailto:contact@aiadspace.com">
                Email: contact@aiadspace.com
              </a>
            </p>
            <div className="d-flex justify-content-end">
              <Nav.Link className="text-primary ms-3" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="text-primary ms-3" href="/portfolio">
                Portfolio
              </Nav.Link>
              <Nav.Link className="text-primary ms-3" href="/services">
                Services
              </Nav.Link>
              <Nav.Link className="text-primary ms-3" href="/about">
                About
              </Nav.Link>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <ul className="social-icons list-unstyled d-flex">
            <li className="mx-2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/profile.php?id=100091780077462"
                className="text-white"
              >
                <FaFacebookF size={24} />
              </a>
            </li>
            <li className="mx-2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:Contact@aiadspace.com"
                className="text-white"
              >
                <FiMail size={24} />
              </a>
            </li>
            <li className="mx-2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/aiadspace/"
                className="text-white"
              >
                <FaLinkedinIn size={24} />
              </a>
            </li>
            <li className="mx-2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/aiadspace?igsh=MTdudG0yd2hmeXptNA=="
                className="text-white"
              >
                <FaInstagram size={24} />
              </a>
            </li>
          </ul>
        </div>
        <div className="text-center d-flex flex-column py-3">
          <small>&copy; 2024 AIAdSpace. All Rights Reserved.</small>
          <small>
            Designed and Created with{" "}
            <FiHeart color="tomato" className="mx-1" />
            by
            <a
              href="https://codeindica.netlify.app/"
              className="text-primary mx-1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Codeindica
            </a>
          </small>
        </div>
      </div>
    </footer>
  );
}
