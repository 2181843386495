import React from "react";
import Banner from "../components/Banner";
import { RiDoubleQuotesL } from "react-icons/ri";
import { FiCheckCircle } from "react-icons/fi";
import Footer from "../components/Footer";
import whyus from "../utils/images/search.png";
import innovation from "../utils/images/innovation.png";
import integrity from "../utils/images/integrity.png";
import ex from "../utils/images/ex.png";
import miss from "../utils/images/miss.png";
import india from "../utils/images/india.png";
import collab from "../utils/images/collab.png";
import customer from "../utils/images/customer-success.png";
import SectionContent from "../components/SectionContent";

export default function About() {
  return (
    <div>
      <Banner
        title={"About AIADspace: Your Partner in Digital Success"}
        subtitle={
          "Welcome to AIADspace, where we empower businesses through strategic branding, creative designs, and digital marketing excellence. Our mission is to help you achieve unparalleled growth and success in the digital landscape."
        }
      />
      <div className="container my-5">
        <h2 className="mar title text-center">Our Story</h2>
        <RiDoubleQuotesL size={90} color="orange" />
        <p className="heading p-4" data-aos="fade-up">
          Founded with a passion for innovation and a commitment to excellence,
          AIADspace has been at the forefront of digital marketing and website
          development since its inception. Over the years, we have partnered
          with over 50 international brands, helping them grow by 200% through
          our unique strategies and cutting-edge solutions.
        </p>
        <img
          src={india}
          alt="india"
          className=" ms-auto d-block"
          style={{ width: "200px" }}
        />

        <h2 className="mar title text-center">Our Mission</h2>
        <RiDoubleQuotesL size={90} color="orange" />
        <p className="heading p-4" data-aos="fade-up">
          To provide exceptional digital marketing, website development, and
          creative design services that drive measurable results and foster
          long-term success for our clients. We strive to be a trusted partner,
          dedicated to helping businesses thrive in a competitive digital world.
        </p>
        <img
          src={miss}
          alt="miss"
          className="ms-auto d-block"
          style={{ width: "200px", height: "100px" }}
        />

        <h2 className="mar title text-center">Our Vision</h2>
        <RiDoubleQuotesL size={90} color="orange" />
        <p className="heading p-4" data-aos="fade-up">
          To be a leading digital agency recognized globally for our innovative
          approach, customer-centric solutions, and unwavering commitment to
          excellence.
        </p>

        <h2 className="mar title text-center">Our Values</h2>
        <div
          className="d-flex flex-wrap justify-content-center align-items-center"
          data-aos="fade-up"
        >
          <div className="box m-2">
            <img src={innovation} alt="innovation" className="sm" />
            <h5 className="subtitle">Innovation</h5>
            <p>
              We embrace creativity and innovation, constantly exploring new
              ideas to stay ahead in the ever-evolving digital landscape.
            </p>
          </div>
          <div className="box m-2">
            <img src={integrity} alt="integrity" className="sm" />
            <h5 className="subtitle">Integrity</h5>
            <p>
              We believe in honesty, transparency, and ethical practices in all
              our interactions and business dealings.
            </p>
          </div>
          <div className="box m-2">
            <img src={ex} alt="excellence" className="sm" />
            <h5 className="subtitle">Excellence</h5>
            <p>
              We are committed to delivering the highest quality of work and
              exceeding our clients' expectations.
            </p>
          </div>
          <div className="box m-2">
            <img src={collab} alt="collaboration" className="sm" />
            <h5 className="subtitle">Collaboration</h5>
            <p>
              We work closely with our clients, fostering strong partnerships to
              achieve shared goals.
            </p>
          </div>
          <div className="box m-2">
            <img src={customer} alt="customer-success" className="sm" />
            <h5 className="subtitle">Customer Success</h5>
            <p>
              Our clients' success is our success. We are dedicated to helping
              them achieve their business objectives and grow.
            </p>
          </div>
        </div>

        <h2 className="mt-4 title text-center pad">Why Choose Us</h2>
        <div className="d-flex justify-content-center align-items-center flex-wrap">
          <div className="col-md-4 p-5 mb-4" data-aos="fade-right">
            <img src={whyus} alt="whyus" className="p-4" />
          </div>
          <div className="col-md-8 pl" data-aos="fade-left">
            <div>
              <div className="d-flex align-items-center">
                <FiCheckCircle size={30} color="gold" />
                <h4 className="m-0 mx-2 subtitle">Proven Track Record</h4>
              </div>
              <p>
                We've helped over 50 international brands achieve significant
                growth and success.
              </p>
            </div>
            <div>
              <div className="d-flex align-items-center">
                <FiCheckCircle size={30} color="gold" />
                <h4 className="m-0 mx-2 subtitle">Customized Solutions</h4>
              </div>
              <p>
                We make our strategies to meet your specific needs and
                objectives
              </p>
            </div>
            <div>
              <div className="d-flex align-items-center">
                <FiCheckCircle size={30} color="gold" />
                <h4 className="m-0 mx-2 subtitle">Comprehensive Services</h4>
              </div>
              <p>
                From digital marketing to web development and creative design,
                we offer a full suite of services to support your business.
              </p>
            </div>
            <div>
              <div className="d-flex align-items-center">
                <FiCheckCircle size={30} color="gold" />
                <h4 className="m-0 mx-2 subtitle">Client-Centric Approach</h4>
              </div>
              <p>
                We prioritize our clients' needs and work collaboratively to
                deliver the best results.
              </p>
            </div>
          </div>
        </div>
      </div>

      <SectionContent
        title=" Let's create something amazing together!"
        link="/contact"
        btnTitle="Start Your Project"
      />
      <Footer />
    </div>
  );
}
